import { inject, injectable } from "inversify";
import Nullable from "../../models/Nullable";
import Pledge from "../../models/treasury/Pledge";
import type ITreasuryRESTClient from "../../rest-clients/ITreasuryRESTClient";
import ITreasuryService from "../../services/ITreasuryService";
import DiscountRequestPayload from "../../models/treasury/discount/DiscountRequestPayload";

@injectable()
export default class TreasuryService implements ITreasuryService{
   
    @inject('ITreasuryRESTClient')
    private treasuryRESTClient! : ITreasuryRESTClient;

    
    getPledges() : Promise<Nullable<Pledge[]>>{
        return this.treasuryRESTClient.getPledges();
    }
    getDiscounts(payload : DiscountRequestPayload) : Promise<string[]>{
        return this.treasuryRESTClient.getDiscounts(payload);
    }

    isImportAvailable(): Promise<boolean> {
        return this.treasuryRESTClient.isImportAvailable();
    }
    importPledges(): Promise<boolean> {
        return this.treasuryRESTClient.importPledges();
    }
}