import { Container } from 'inversify'
import RecordSheetService from './services/Implementations/RecordSheetService'
import RecordSheetRESTClient from './rest-clients/implementations/RecordSheetRESTClient';
import SettlementModelMapperService from './services/Implementations/SettlementModelMapperService';
import SurvivorModelMapperService from './services/Implementations/SurvivorModelMapperService';
import RefreshHandlerService from './services/Implementations/RefreshHandlerService';

export default function BootStrapRecordSheets(container: Container){
    container.bind('IRecordSheetService').to(RecordSheetService).inSingletonScope();
    container.bind('IRecordSheetRESTClient').to(RecordSheetRESTClient).inSingletonScope();
    container.bind('ISettlementModelMapperService').to(SettlementModelMapperService);
    container.bind('ISurvivorModelMapperService').to(SurvivorModelMapperService);
    container.bind('IRefreshHandlerService').to(RefreshHandlerService).inSingletonScope();
    
}